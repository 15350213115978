<template>
  <div class="user-withdrawal">
    <div class="user-withdrawal-box">
      <div class="header">
        <div class="title">到账银行卡</div>
        <div v-if="activeBank" class="cont" @click="selectBankList">
          <div class="icon">
            <img :src="activeBank.bankLogo" alt="">
          </div>
          <div class="ct">
            <div class="t">{{ activeBank.bankName }}({{ activeBank.cardNo | getBankNo }})</div>
            <div class="c">15个工作日内到账</div>
          </div>
          <div class="right">
            <van-icon name="arrow" />
          </div>
        </div>
        <div v-else class="cont" @click="goAddBank">
          <div class="icon"><van-icon name="add-o" /></div>
          <div class="ct">
            <div class="t">添加银行卡</div>
            <div class="c">您还未绑定银行卡，请先添加</div>
          </div>
          <div class="right">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div class="content">
        <div class="title">提现金额</div>
        <div v-if="infos.balance" class="cont">
          <div class="unit">￥</div>
          <van-field v-model="form.amount" type="number" :placeholder="`可提现到卡${(infos.balance / 100).toFixed(2)}元`" :border="false" @input="onInput" />
          <div class="per" @click="setAll">全部</div>
        </div>
      </div>
      <div class="bottom">
        <van-button
          round
          block
          type="info"
          color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
          style="margin-bottom: 20px;"
          @click="submitApply"
        >
          提现
        </van-button>
        <div class="btn" @click="goList">查看提现记录</div>
      </div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <div class="popup">
        <div class="header">
          <div class="btn" @click="goCancel">取消</div>
          <div class="title">选择银行卡</div>
          <div class="btn" />
        </div>
        <div v-if="bankList.length > 0 && activeBank" class="list">
          <div v-for="item in bankList" :key="item.id" class="item" :class="{ selected: item.id == activeBank.id }" @click="goSelectBank(item)">
            <div class="name">{{ item.bankName }}{{ item.cardNo | getBankNo }}</div>
            <div v-if="item.id == activeBank.id" class="icon"><van-icon name="success" /></div>
          </div>
        </div>
        <div class="bottom" @click="goAddBank">
          <div class="icon"><van-icon name="add-o" /></div>
          <div class="title">添加银行卡</div>
          <div class="right"><van-icon name="arrow" /></div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getAccountInfo, submitApply } from '@/api/user'
import { getCardList } from '@/api/finance'
import { getOssUrl } from '@/utils/oss'
export default {
  filters: {
    getBankNo(num) {
      return num.slice(-4)
    }
  },
  data() {
    return {
      infos: {},
      form: {
        amount: '',
        bankCardId: ''
      },
      activeBank: null,
      bankList: [],
      show: false
    }
  },
  mounted() {
    this.getWithdrawal()
    this.getCardList()
  },
  methods: {
    onInput(item) {
      if (item[0] === '0') {
        this.form.amount = item.substring(1)
      }
    },
    selectBankList() {
      this.show = true
    },
    goSelectBank(item) {
      this.activeBank = item
      this.form.bankCardId = item.id
      this.show = false
    },
    goAddBank() {
      this.$router.push({ name: 'bankAdd' })
    },
    goList() {
      this.$router.push({ name: 'userWithdrawalList' })
    },
    goWithdrawal() {
      //
    },
    setAll() {
      this.form.amount = (this.infos.balance / 100).toFixed(2)
    },
    goCancel() {
      this.show = false
    },
    async submitApply() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await submitApply(this.form)
      loading.clear()
      this.$router.push({ name: 'userWithdrawalDetail', params: { id: res.datas.id }})
    },
    async getCardList() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getCardList()
      const list = res.datas || []
      for (let i = 0; i < list.length; i++) {
        const item = { ...list[i] }
        item.bankLogo = await getOssUrl(item.bankLogo)
        this.bankList.push(item)
      }
      this.activeBank = this.bankList.length > 0 ? this.bankList[0] : null
      if (this.activeBank) {
        this.form.bankCardId = this.activeBank.id
      }
      loading.clear()
    },
    async getWithdrawal() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getAccountInfo()
      this.infos = res.datas
      loading.clear()
    }
  }
}
</script>
<style lang="scss" scoped>
.user-withdrawal {
  padding: 16px;
  .user-withdrawal-box {
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
    overflow: hidden;
    .header {
      display: flex;
      padding: 16px;
      background: #F5F5F7;
      .title {
        font-size: 14px;
      }
      .cont {
        display: flex;
        flex: 1;
        padding-left: 24px;
        .icon {
          font-size: 18px;
          color: #ff7700;
          img {
            display: block;
            width: 20px;
          }
        }
        .ct {
          flex: 1;
          padding-left: 10px;
          .c {
            padding-top: 5px;
            font-size: 12px;
            color: #999999;
          }
        }
        .right {
          color: #7f7e7e;
        }
      }
    }
    .content {
      padding: 16px;
      .title {
        font-size: 12px;
      }
      .cont {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #F3F3F5;
        .unit {
          font-size: 24px;;
        }
        .van-cell::v-deep {
          flex: 1;
          font-size: 32px;
          input::placeholder {
            font-size: 14px;
          }
        }
        .per {
          color: #FF7700;
        }
      }
    }
    .bottom {
      padding: 24px;
      .btn {
        text-align: center;
        color: #2697FF;
      }
    }
  }
  .popup {
    padding: 16px;
    .header {
      display: flex;
      align-items: center;
      .btn {
        min-width: 28px;
        text-align: center;
        font-size: 14px;
        color: #999999;
      }
      .title {
        flex: 1;
        text-align: center;
        font-size: 16px;
      }
    }
    .list {
      padding: 20px 0;
      .item {
        display: flex;
        align-items: center;
        padding: 12px 0;
        font-size: 16px;
        &.selected {
          color: #FF7700;
        }
        .name {
          flex: 1;
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      .icon {
        font-size: 18px;
        color: #ff7700;
      }
      .title {
        flex: 1;
        padding-left: 16px;
        font-size: 16px;
      }
      .right {
        color: #999999;
      }
    }
  }
}
</style>
